import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import SupplierBenefits from './SupplierBenefits';
import { Spinner } from '@commonsku/styles';
import { BASE_ZINDEX } from '../popup-factory';
import { closePopup } from '../actions/popup';
import { createFetchDivision } from '../actions/supplier';

const SupplierBenefitsPopup = ({ division_id, division, index, dispatch }) => {
  useEffect(() => {
    if (!division) {
      dispatch(createFetchDivision(division_id));
    }
  }, [division_id]);
  return (
    <div className="reveal medium" style={{ display: 'block', zIndex: BASE_ZINDEX + index, height: 'auto' }} role="dialog">
      {!division ? <Spinner /> : <SupplierBenefits supplier={division} />}
      <button className="button close-button" style={{ fontSize: 'initial', color: 'white' }} aria-label="Close" type="button" onClick={() => dispatch(closePopup())}> Close
      </button>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  division: state.entities.divisions[ownProps.division_id]
});

export default connect(mapStateToProps)(SupplierBenefitsPopup);
